import React,{useEffect, useState,useContext} from 'react';
import { Link } from 'react-router-dom';
// import RecordVideo from './RecordVideo';
import Spo2 from '../module1/preview/Screen2-VitalSign1.png'
import HeartRate from '../module1/preview/Screen2-VitalSign2.png'
import BPressure from '../module1/preview/Screen2-VitalSign3.png'
// import Temp from '../module1/preview/Screen2-VitalSign4.png'
// import BPressure from '../module1/preview/Screen2-VitalSign5.png'
// import EyeColor from '../module1/preview/Screen2-VitalSign6.png'
import Breathing from '../module1/preview/Screen2-VitalSign7.png'
import Healthy from '../module1/WebAppModule1_Screen5Green.png' 
import Abnormal from '../module1/preview/Screen5-CautionIcon.png' 
import LoadingIcon from '../module1/preview/Screen2-LargerLoadingIcon.gif' 
import HealthyMarker from '../module1/preview/Screen3-HealthyMarker.png' 
import AbnormalMarker from '../module1/preview/Screen5-CautionMarker.png' 
import CautionMarker from '../module1/preview/Screen4-AbnormalMarker.png' 
// import AbnormalMarker from '../module1/preview/Screen5-CautionMarker.png' 
import Caution from '../module1/WebAppModule1_Screen6Yellow.png'
import V2 from '../module1/TabBar-Wellness@2x.png'
import Health from '../module1/TabBar-Healthiness@2x.png'
import Wellness from '../module1/TabBar-Wellness_1@2x.png'
// import 'react-notifications/lib/notifications.css';
// import {NotificationContainer, NotificationManager} from 'react-notifications';

import Smile from  '../module1/WebAppModule1_Screen8PopSmile.png'
// import { useContext } from 'react/cjs/react.production.min';
// import { AuthContext } from '../App';  
// import 
import HeartIcon from '../images/icons/HR.png'
import BRIcon from '../images/icons/BR.png'
import SpO2Icon from '../images/icons/Spo2.png'
import BPIcon from '../images/icons/BP.png'
import BSIcon from '../images/icons/Sugar.png'
import CLIcon from '../images/icons/Cholesterol.png'

import GreenBox from '../images/UserHMSAssets/animation/Green box.png'
import NoMask from '../images/UserHMSAssets/animation/No mask.png'
import NoClasses from '../images/UserHMSAssets/animation/No glasses.png'
import LightUp from '../images/UserHMSAssets/animation/Lit up bg.png'
import AnimatedText from 'react-animated-text-content';

import SkipVideo from '../images/SkipVid.mp4'

const Security = () => {
    // https://codesandbox.io/s/zhde4?file=/src/App.js
    const [healthstatus, setHealthStatus] = useState('')
    const [icon, setIcon]=useState('')
    const [img, setImg]=useState(LoadingIcon)
    const [greenb,setGreenB]=useState('')
    const [nom,setNoM]=useState('')
    const [noc,setNoC]=useState('')
    const [lu,setLu]=useState('')
    const [animatetext, setAnimateText]=useState('')
    const [startani, setStartAni]=useState(0)

    const [skipdisplay,setSkipDelay]=useState('inline')
    const [scanui,setScanUI]=useState('hidden')
    const [selectorValue,setSelector] = useState(1)

    const toggleMe=()=>{

      setSkipDelay('hidden')
      setScanUI('inline')
     }

    useEffect(() => {
        const timerg = setTimeout(() => {
        //   console.log('This will run after 1 second!')
        setGreenB(GreenBox)
        }, 1000);
        return () => clearTimeout(timerg);
      }, []);
    useEffect(() => {
        const timern = setTimeout(() => {
        //   console.log('This will run after 1 second!')
        setNoM(NoMask)
        }, 2500);
        return () => clearTimeout(timern);
      }, []);
    useEffect(() => {
        const timerc = setTimeout(() => {
        //   console.log('This will run after 1 second!')
        setNoC(NoClasses)
        }, 4000);
        return () => clearTimeout(timerc);
      }, []);
    useEffect(() => {
        const timerlu = setTimeout(() => {
        //   console.log('This will run after 1 second!')
        setLu(LightUp)
        }, 5000);
        return () => clearTimeout(timerlu);
      }, []);
    useEffect(() => {
        const timerls = setTimeout(() => {
        //   console.log('This will run after 1 second!')
        if(startani==1){
          setAnimateText('Look at the camera')

        }
        
        }, 1000);
        return () => clearTimeout(timerls);
      }, [startani]);
    useEffect(() => {
        const timerls = setTimeout(() => {
        //   console.log('This will run after 1 second!')
        if(startani==1){
          setAnimateText('Stay Still...')

        }
        
        }, 2500);
        return () => clearTimeout(timerls);
      }, [startani]);
    useEffect(() => {
        const timerls = setTimeout(() => {
        //   console.log('This will run after 1 second!')
        if(startani==1){
          setAnimateText('Hold On...')

        }
        
        
        }, 4000);
        return () => clearTimeout(timerls);
      }, [startani]);
    useEffect(() => {
        const timerls = setTimeout(() => {
        //   console.log('This will run after 1 second!')
        
        if(startani==1){
          setAnimateText('Wait a second...')

        }
        }, 5000);
        return () => clearTimeout(timerls);
      }, [startani]);
    useEffect(() => {
        const timerls = setTimeout(() => {
        //   console.log('This will run after 1 second!')
        
        if(startani==1){
          setAnimateText('')
          setStartAni(0)

        }
        }, 6000);
        return () => clearTimeout(timerls);
      }, [startani]);
    
    // const { auth, dispatchAuth } = useContext(AuthContext)
    //  const { auth, dispatchAuth } = useContext(AuthContext)
    useEffect(() => {
        document.getElementById("scan").click();

        // return () => {
        //     document.getElementById("stop").click();
            
        // }
        
    }, [])

     useEffect(() => {
  const interval = setInterval(() => {
    // console.log('This will run every second!');
      
      getVal()
  }, 2000);
  return () => clearInterval(interval);
    }, []);

    useEffect(()=>{
        setIcon('')
        setImg('')

    },[])

    useEffect(()=>{
        renderstatus()

    },[healthstatus])


    const getVal=()=>{
       var status= document.getElementById("diag_value").value

    //    document.getElementById("demo").innerHTML = status;

       console.log(status)
       if(status!=healthstatus){
        setHealthStatus(status)
       }
    }


    const renderstatus=()=>{

        if(healthstatus==1){
                setImg(Healthy)
                setIcon(HealthyMarker)
            

            return 

        }else if(healthstatus==2){
            //LoadingIcon
            // caution
            setImg(Caution)
            setIcon(CautionMarker)
        }
        else if(healthstatus==3){
            //LoadingIcon
            // abnormal
            setImg(Abnormal)
            setImg(AbnormalMarker)
        }else{
            setImg(LoadingIcon)
            setIcon('')

        }


    }
    


    
   
    const reClick = () => {
        // alert('hello');
        // document.getElementById("scan").click();
        // document.getElementById("scan").click();

        // NotificationManager.info('Loading....');
        

        // openDialog2()


        

        // setTimeout(function () {
        //     reClick();
        // }, 5000);
    }
    const renderanimation=()=>(
        <AnimatedText
  type="chars" // animate words or chars
  animation={{
    x: '200px',
    y: '-20px',
    scale: 1.1,
    ease: 'ease-in-out',
  }}
  animationType="lights"
  interval={0.1}
  duration={0.1}
  tag="p"
  className="animated-paragraph"
  includeWhiteSpaces
  threshold={0.1}
  rootMargin="20%"
>
  {animatetext}
</AnimatedText>
    )
    
    return (<div className='antialiased bg-white font-ssans flex flex-col'>

        
        <div class=" flex  md:flex-row  bg-white  md:ml-4  space-y-2 flex-col">
        <div className=' my-4 space-y-0 px-8'>
               <div className='md:pl-10 flex md:flex-col flex-row'>
                {greenb?<>
                  <img src={greenb} className=" md:h-32 md:w-32  h-20 w-20" alt='' />
                </>:null}
                {nom?<>
                  <img src={nom} className=" md:h-32 md:w-32  h-20 w-20" alt='' />
                </>:null}
                {noc?<>
                  <img src={noc} className=" md:h-32 md:w-32  h-20 w-20" alt='' />
                </>:null}
                {lu?<>
                  <img src={lu} className=" md:h-32 md:w-32  h-20 w-20" alt='' />
                </>:null}
                
                
                
                
                </div> 
            </div>
            <div class="">
                <div id="vital_history" > 
                </div>
                    <br />
                    <span className={`wrapper ${skipdisplay}`}>
                    {/* <div className='my-2'><button onClick={()=>toggleMe()} type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Skip Video</button></div> */}
                    <div className='my-2'><button onClick={()=>toggleMe()} type="button" class=" ml-2 md:inline hidden text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Skip Video</button></div>
                    {/* <iframe controls={false} autoPlay={1} class="w-fill aspect-video ... ml-3" src={SkipVideo} width="640"  height="480"></iframe> */}
                    <iframe class="w-full aspect-video  ml-3  md:w-[40rem] md:h-[30rem]" src={SkipVideo} ></iframe>
                    <div className='my-2'><button onClick={()=>toggleMe()} type="button" class=" ml-2 md:hidden inline text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Skip Video</button></div>

                    </span>
                <div className={`wrapper ${scanui}`}>
                <div>
                    <h2 id="checker" hidden>Modules Loading</h2>
                    <p hidden>FPS : <span id='fps_msg'></span></p>
                    <div className="navi bg-black border-2 shadow-2xl shadow-black">
                    
                    <video hidden id="video"  width="640" className='w-full' height="480" autoPlay></video>
                    <input id='selector_index' value={selectorValue} hidden readOnly></input>
                    <canvas id="canvas"  width="640" className='w-full' height="480"></canvas>
                    <div className='progress__container' hidden>
                        <div id="progress__value"></div>
                    </div>
                    </div>
                </div>
                <div className=" w-full h-full grid grid-flow-row grid-cols-2" id="infoi">

                  <div className="flex flex-col m-4 justify-between">
                      {/* Left part */}
                      <div>
                                <div className="bg-no-repeat h-20 md:h-28 bg-contain" style={{ backgroundImage: `url(${BPressure})`}} >
                              
                              <div className='hidden md:inline'>
                              < br />
                                < br />
                              </div>  
                                
                                
                                    <span className="mt-20 text-white mx-auto ml-10 md:ml-6"> <input className=" ml-2 bg-inherit border-0 w-12 text-white text-extrabold text-2xl font-black md:mt-1 mx-auto mt-7 md:10"  readOnly id="bs_rate" /> </span>
                                    <br /> <span className='text-white font-bold ml-3 md:ml-6'>mg/dL</span> 
                                
                            
                            </div>
                            <span className="text-sm text-white font-bold ml-1" >Blood Sugar</span>

                      </div>
                      {/* Basic Module elements */}
                      <div className="scan__Box" hidden>
                          <label htmlFor='heart_rate'>Heart Rate</label><br/>
                          <input type="text" id="heart_rate"/>
                      </div>
                      <div className="scan__Box" hidden>
                          <label htmlFor='resp_rate'>Respiratory Rate</label><br/>
                          <input type="text" id="resp_rate"/>  
                      </div>
                      <div className="scan__Box" hidden>
                          <label htmlFor='spo2'>Spo2 Level</label><br/>
                          <input type="text" id="spo2"/>  
                      </div>
                      {/* Blood Pressure Module Elements */}
                      <div className="scan__Box" hidden>
                          <label htmlFor='blood_pressure'>Blood Pressure</label><br/>
                          <input type="text" id="blood_pressure"/>  
                      </div>
                      {/* Blood Cholesterol Module Elements */}
                      <div className="scan__Box" hidden>
                          <label htmlFor='chol_rate'>Blood Cholesterol</label><br/>
                          <input type="text"  id="chol_rate"/>
                      </div>
                      {/* Use to get Information about the modules */}
                      <input hidden id='check_basic' readOnly/>
                      <input hidden id='check_bp' readOnly/>
                      <input hidden id='check_bs' readOnly/>
                      <input hidden id='check_bc' readOnly/>

                      
                      <div>
                      {/* <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                          
                              <a href='https://patient-test.basic.docsun.health/landing'>
                              <div className='flex flex-row'>
                              <div>
                              <i class="fa fa-arrow-left" aria-hidden="true"></i> 
                              </div>
                              <div>
                                Back to <br /> Dashboard
                              </div>
                              </div>
                              </a>
                          
                          
                          </button> */}
                          <div className='text-blue-500 text-sm flex flex-col w-full'>
                          <div className='text-white md:text-2xl font-bold'>{renderanimation()}</div>

                          <button id="start_stop_btn" value="1" className='btn btn-info text-white rounded-lg mx-auto' onClick={()=>setStartAni(1)}>CLICK HERE TO START VITAL SCANNING</button>
                      </div>
                      </div>
                      
                      

                      {/* <div className="bg-no-repeat  h-28 bg-contain" style={{ backgroundImage: `url(${Spo2})`}} >
                          < br />
                          <div className='flex flex-col'>
                          <input  className=" bg-inherit border-0 w-12 mx-8 text-white text-3xl text-extrabold font-black mt-1" readOnly id="spo2_rate" /> 
                          <div>
                          <span className='text-white font-bold ml-10 mt-18'>%</span>
                            </div>
                          </div>

                          
                      </div>
                      <span className="text-white font-bold ml-2" >SpO <sup>2</sup>Levels</span> */}

                      {/* <div className="bg-no-repeat h-28 bg-contain" style={{ backgroundImage: `url(${HeartRate})`}} >
                      
                          < br />
                          <div className='flex flex-col'>
                          <input type="text" className=" bg-inherit border-0 w-16 mx-8 text-white text-3xl text-extrabold font-black mt-1" readOnly id="heart_rate" />
                        <span className='text-white font-bold ml-10 mt-18'>BPM</span>

                          </div>
                        
                      </div>
                      <span className=" text-white font-bold ml-3" >Heart Rate</span> */}

                      {/* <div className="bg-no-repeat h-20 bg-contain" style={{ backgroundImage: `url(${BPressure})`}} >
                          < br />
                        <input className=" bg-inherit border-0 w-10 mx-6 text-white text-2xl text-extrabold font-black mt-1" readOnly id="bs_rate" /> 
                        <br />
                        <span className='text-white font-bold ml-3'>mg/dL</span> 
                      </div>
                        <span className="text-sm text-white font-bold" >Blood Sugar</span> */}
                      
                  </div>
                  <div className="flex flex-row-reverse mt-4">
                            {/* right part */}
                            

                      <div className="flex flex-col">
                        {/* <div>
                                <div className="bg-no-repeat h-20 bg-contain" style={{ backgroundImage: `url(${BPressure})`}} >
                                < br />
                                
                                    <span className="mt-10 text-white"> <input className=" bg-inherit border-0 w-10 text-white text-extrabold font-black mt-1"  readOnly id="syst_rate" /> <b>- </b> / <input className=" bg-inherit border-0 w-10 text-white text-extrabold font-black mt-1" readOnly id="diast_rate" />  </span>
                                    <br /> <span className='text-white font-bold ml-6'>mmHg</span> 
                                
                            
                            </div>
                            <span className="text-sm text-white font-bold mr-3" >Blood Pressure</span>

                      </div> */}
                     

                      {/* <div>
                          <div className="bg-no-repeat h-28 bg-contain mr-4" style={{ backgroundImage: `url(${Breathing})`}} >
                          < br />
                            <span className=" mt-10 text-white"> <input className=" bg-inherit border-0 w-12 mx-8 text-white text-3xl text-extrabold font-black mt-1" readOnly id="br_rate" /> </span>
                            <br /> 
                            <span className='text-white font-bold ml-10 mt-18'>RPM</span> 
                      </div>
                      <span className=" text-white font-bold text-1xl" >Breathing Rate</span>

                      </div> */}
                      <div hidden>
                          <img src={img} className=" h-28 mr-4" />

                      </div>

                      </div>
                      
                      
                  </div>

              </div>
            </div>
                <div className="flex m-3">
                    <div className='space-x-4 flex flex-row'>
                    <button hidden id="scan" className='btn hidden'>Start Idle Scanning</button>
                    {/* <button id="real_scan" className='btn btn-info text-white rounded-lg'>Start Vital Scanning</button> */}
                    {/* <button id="stop" hidden className='btn btn-secondary bg-red-500 rounded-lg text-white'>Stop Vital Scanning</button> */}
                    <button id="stop" hidden >Stop Vital Scanning</button>

                    <div className='mx-auto flex flex-col'>
                      <div className="mx-auto items-center">
                          <img src={BSIcon} className="w-10" />
                          
                      </div>
                      <div>
                      Blood Sugar <br />
+/-18 mg/dL

                      </div>

                    </div>
    
        
                    </div>
                <div className='ml-6'>
                    <div>

                        <div class="col-sm hidden">
                            {/* <img hidden id="spo2_img" src="./vitalbox.png" alt="" /> <canvas class="spo2_canvas" width="480" height="130"></canvas><canvas class="spo2_canvas" width="480" height="130"></canvas>
                            <img hidden id="br_img" src="./vitalbox.png" alt="" /> <canvas class="br_canvas" width="480" height="130"></canvas><canvas class="br_canvas" width="480" height="130"></canvas>
                            <img hidden id="hr_img" src="./vitalbox.png" alt="" /> <canvas class="hr_canvas" width="480" height="130"></canvas><canvas class="hr_canvas" width="480" height="130"></canvas> */}
                            <img hidden id="sr_img" src="./vitalbox.png" alt="" /> <canvas class="sr_canvas" width="320" height="130"></canvas>
    <img hidden id="dr_img" src="./vitalbox.png" alt="" /> <canvas class="dr_canvas" width="320" height="130"></canvas>
                        </div>
                        
                    </div>
                </div>
            </div>
 
  
  
            <h4 hidden> Result :  <input type="text" id="diagnosis" hidden className='text-3xl font-black' /><input type="text" hidden id="diag_value" /><input hidden type="text"  id="diag_score"/></h4>
            <p>Message : <span id='prog_dynamic'></span></p>
        
            </div>
            
            
        </div> 
        {/* end */}
        {/* <div className=' md:mx-32 flex flex-col mb-4'>
        <div className='font-bold'> Instructions to Follow For Accurate Scanning : </div>
        <ul className='list-disc ml-5'>
            <li> Please load the page and enable the internal or external camera to scan.</li>
            <li> Position the camera accordingly in front of you and perform the scan in a well-lit environment.</li>
            <li> Do not do the scan under too much backlight.</li>
            <li> Adjust your face position to the camera until the green frame is steadily shown and please stay still.</li>
            <li> Remove facial accessories such as masks, eyeglasses, and contact lenses for better accurate detection.</li>
            <li> The scan lasts until the health status shows up on the screen. You can click “Stop Scanning” to see your stable result.</li>

        </ul>

        </div> */}
        
  
       
    </div>)
}
 
export default Security;